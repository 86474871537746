import styled from "styled-components";

export const Modal = styled.div`
  position: relative;
  z-index: 999;
`;
export const BoxModal = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ComponentModal = styled.div`
  background-color: #fff;
  border-radius: 6px;
  /* height: 367px; */
  padding: 30px;
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
`;
export const ModalImg = styled.img`
  margin-bottom: 36px;
`;
export const ModalTitle = styled.h6`
  font-size: 18px;
  font-family: TitilliumWeb-Regular;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: center;
  color: #231d19;
`;
export const ModalDescription = styled.p`
  font-size: 14px;
  font-family: TitilliumWeb-Regular;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #231d19;
  margin-bottom: 40px;
`;
export const ModalBtn = styled.div`
  display: block;
  padding: 13px 0;
  width: 246px;
  border-radius: 25px;
  background-color: #0095d3;
  color: #fff;
  text-align: center;
  cursor: pointer;
`;
export const BoxOpt = styled.div``;
export const LabelRadioOpt = styled.label`
  font-family: TitilliumWeb-Regular;
  background-color: #ffffff;
  border: 1px solid #212121;
  border-radius: 25px;
  padding: 10px 35px;
  color: #212121;
  font-size: 16px;
  display: inline-block;
  cursor: pointer;

  @media (min-width: 375px) {
    padding: 10px 50px;
  }

  @media (min-width: 768px) {
    padding: 10px 66px;
  }
`;
export const InputRadioOpt = styled.input`
  position: absolute;
  visibility: hidden;
  display: none;
  font-family: TitilliumWeb-Regular;

  &[checked] + .checkmarkinput {
    color: #ffffff;
    background-color: #0095d3;
    border: 1px solid #0095d3;
  }
  &[checked] + .checkmarkinput:after {
    display: block;
  }
`;
export const Description6 = styled.h4`
  font-family: TitilliumWeb-Regular;
  color: #212121;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  padding-bottom: 20px;

  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 1.17;
    padding-bottom: 24px;
  }
`;
export const Row = styled.div`
  margin-top: 5px;
`;
export const Label = styled.label`
  font-family: TitilliumWeb-Regular;
  color: #212121;
  line-height: 30px;
  font-size: 16px;
  height: 20px;
  opacity: 60%;
  margin: 0px 0px 10px 12px;
  display: block;
`;
export const Select = styled.select`
  font-family: TitilliumWeb-Regular;
  max-width: 743px;
  height: 46px;
  width: 100%;
  height: 46px;
  border: 1px #212121 solid;
  border-radius: 23px;
  background-color: transparent;
  outline: 0;
  color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  appearance: none;
  margin-right: 5px;
  background: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>')
    100% 50% no-repeat transparent;
`;
export const Small = styled.h4`
  font-size: 24px;
  line-height: 1;
  font-family: TitilliumWeb-Regular;
  color: #212121;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: 10px 0 10px 0;

  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 1.17;
    margin: 10px 0 10px 0;
  }
`;
export const School = styled.div`
  @media (min-width: 992px) {
    margin-bottom: 20px;
  }
`;
export const LabelButton = styled.label`
  font-family: TitilliumWeb-Regular;
  color: #212121;
  line-height: 30px;
  font-size: 15px;
  height: 20px;
  text-align: left;
  opacity: 60%;
  margin-bottom: 6px;
  display: block;

  @media (min-width: 992px) {
    margin-bottom: 10px;
    margin-left: 10px;
  }
`;
export const BoxSchool = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 396px;
`;
export const BoxContentBoxButtons = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px auto;

  @media (min-width: 768px) {
    justify-content: initial;
    margin: 16px auto 0 0;
  }
`;
export const BoxContentButton = styled.a`
  font-family: TitilliumWeb-Medium;
  font-size: 16px;
  color: #ffffff;
  white-space: nowrap;
  background-color: #21294f;
  height: 40px;
  width: 100%;
  border-radius: 24px;
  text-decoration: none;
  padding: 9px 40px;

  display: flex;
  justify-content: center;
  align-items: baseline;

  img {
    width: 17px;
    height: auto;
    margin-left: 7px;
  }
  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
  & + a {
    margin-left: 17px;
    display: none;
  }
  @media (min-width: 768px) {
    width: unset;
  }
  @media (min-width: 1024px) {
    & + a {
      display: block;
    }
  }
`;
