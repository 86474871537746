import { getEnv } from '../utils';
let s3Env = getEnv();
let host;
let protocol;

switch (getEnv()) {
	case 'dev':
		host = 'sta-lb-portalweb-dev-467455392.us-east-1.elb.amazonaws.com';
		protocol = 'http:';
		break;
	case 'hml':
		host = 'homol.santillana.com.br';
		protocol = 'https:';
		break;
	case 'prd':
		host = 'santillana.com.br';
		protocol = 'https:';
		break;
	default:
		s3Env = 'undefined';
		host = 'undefined';
		protocol = 'undefined';
		break;
}

let axiosConfig = {
	development: {
		baseURL: `${protocol}//${host}/cms/index.php/wp-json`,
		contentUrl: `${protocol}//${host}/cms/wp-content`,
		uploadsUrl: `https://sta-s3-${s3Env}.s3.amazonaws.com/cms/wp-content/uploads`,
		apiUrl: `${protocol}//${host}/core/api`,
		apiUrlSimulator: `${protocol}//${host}/simulator/api`
	},
	production: {
		baseURL: `${protocol}//${host}/cms/index.php/wp-json`,
		contentUrl: `${protocol}//${host}/cms/wp-content`,
		uploadsUrl: `https://sta-s3-${s3Env}.s3.amazonaws.com/cms/wp-content/uploads`,
		apiUrl: `${protocol}//${host}/core/api`,
		apiUrlSimulator: `${protocol}//${host}/simulator/api`
	}
}

export default axiosConfig[process.env.NODE_ENV];