import React from 'react';
import {
    Modal,
    ModalBtn,
    BoxModal,
    ComponentModal,
    BoxOpt,
    InputRadioOpt,
    LabelRadioOpt,
    Row,
    Label,
    Select,
    Small,
    School,
    LabelButton,
    BoxSchool,
    BoxContentBoxButtons,
    BoxContentButton
} from './modal-message.styled.js';
import downloadWhiteIcon from './assets/download-white.svg';
import emailWhiteIcon from './assets/icone-email2.svg';


const ModalMessage = (props) => {

    const { buttonAction } = props;

    return <Modal>
        <BoxModal>
            <ComponentModal>
                <Row>
                    <Small>Dados pessoais</Small>
                    <Label>Cargo*</Label>
                    <Select
                        name="cargo"

                    >
                        <option value="">Selecione</option>
                    </Select>
                </Row>
                <Row>
                    <Small>Dados da escola​</Small>
                    <School>
                        <LabelButton>Tipo de escola*</LabelButton>
                        <BoxSchool>
                            <BoxOpt key="privada">
                                <InputRadioOpt
                                    type="radio"
                                    name="btn_school"
                                    id={"schlPrivada"}
                                    value="Privada"
                                />
                                <LabelRadioOpt
                                    className="checkmarkinput"
                                    htmlFor="schlprivada"
                                >
                                    Privada
                                </LabelRadioOpt>
                            </BoxOpt>
                            <BoxOpt key="Publica">
                                <InputRadioOpt
                                    type="radio"
                                    name="btn_school"
                                    id={"schlPublica"}
                                    value="Privada"
                                />
                                <LabelRadioOpt
                                    className="checkmarkinput"
                                    htmlFor="schlpublica"
                                >
                                    Publica
                                </LabelRadioOpt>
                            </BoxOpt>
                        </BoxSchool>
                    </School>
                </Row>
                <BoxContentBoxButtons>
                    <BoxContentButton
                        id="btn-simulador-baixar-pdf"
                        role="button"
                        target="_blank"
                        onClick={buttonAction}
                    /* href={`/simulador/pdf/Resultado-SimuladorSantillana-${simulationId}.pdf`} */
                    >
                        Baixar
                        <img src={downloadWhiteIcon} alt='Baixar relatório' />
                    </BoxContentButton>
                    <BoxContentButton
                        id="btn-simulador-enviar-email"
                        role="button"
                        target="_blank"
                        onClick={buttonAction}
                    >

                        Enviar
                        <img src={emailWhiteIcon} alt='Enviar relatório' />
                    </BoxContentButton>
                </BoxContentBoxButtons>
            </ComponentModal>
        </BoxModal>
    </Modal>
}

export default ModalMessage;