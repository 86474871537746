export const getEnv = () => {
    let env;

    if (process.env.NODE_ENV === 'production') {
        if (
            window.location.port === '5000' ||
            window.location.host === "www.gruposantillana.com.br" ||
            window.location.host === "www.santillana.com.br"
        ) {
            env = 'prd';
        } else if (window.location.host === "homol.santillana.com.br") {
            // Hml
            env = 'hml';
        } else if (window.location.host === "sta-lb-portalweb-dev-467455392.us-east-1.elb.amazonaws.com") {
            // Dev
            env = 'dev';
        } else {
            env = 'dev';
        }
    } else {
        env = 'dev';
    }

    return env;
}